

import Vue from "vue"
import { Store } from "../../store"
import { ClientInvitation, ClientInvitationInfoResponse, SnackbarInfo, ChangeActiveRequest, ChangeManualApprovalRequest} from "@/types/responses"

export default Vue.extend({
    name: "ShowInvitationDialog",
	props: {
		showInvitation: {
			type: Boolean,
		}
	},
    async mounted() {
        if(!this.hasFetchedInfo()){
			await this.refreshClientInvitationInfo();
		} else {
            this.loadingInfo = false;
        }

        this.loaded = true
	},
	data(): {
		snackBar: boolean
		snackBarText: string
        copied: boolean
        loaded: boolean
        loadingInfo: boolean
		loadingActive: boolean
        loadingApproval: boolean
		timeLeft: number

        checkbox: boolean

        deleteDialog: boolean
	} {
		return {
			snackBar: false,
			snackBarText: "",
            copied: false,
            loaded: false,
            loadingInfo: false,
			loadingActive: false,
            loadingApproval: false,
			timeLeft: 1,

            checkbox: false,

            deleteDialog: false
		}
	},
    watch: {
		clientInvitationInfo: {
            deep: true,
            handler(){
                console.log("updated clientInvitationInfo()")

                // Error handler: wrong data loaded
                if(
                    this.clientInvitationInfo &&
                    this.clientInvitationInfo.jobId !== 
                    this.clientInvitation!.id
                ){
                    this.refreshClientInvitationInfo()
                }
            }
        }
	},
    computed: {
        clientInvitation(): ClientInvitation {
            return this.$store.state.context!.selectedClientInvitation 
        },
		clientInvitationInfo(): ClientInvitationInfoResponse | null {
            if(this.loaded && !this.loadingInfo) return this.$store.state.context!.selectedClientInvitationInfo
            return null
        },

        invitationUrl(): string {
            if(this.clientInvitationInfo && !this.loadingInfo){
                const registrationBaseUrl = (this.$store as Store).state.config.registrationBaseUrl;
                return registrationBaseUrl + (registrationBaseUrl.endsWith("/") ? this.$store.state.context!.selectedClientInvitationInfo!.linkCode : "/" + this.$store.state.context!.selectedClientInvitationInfo!.linkCode);
            }
            return ""
        },

        activeStatus(): string {
            if(this.clientInvitationInfo && !this.loadingInfo){
                return "Active" 
            }
            return ""
        },

        invitationManualApproval(): boolean | null {
            if(this.clientInvitationInfo && !this.loadingInfo){
                return this.$store.state.context!.selectedClientInvitationInfo.manualApproval
            }
            return null
        },
	},
	methods: {
        hasFetchedInfo(): boolean {
			if(this.$store.state.context!.selectedClientInvitationInfo && this.$store.state.context!.selectedClientInvitation){
                const selectedClientInvitationInfo: ClientInvitationInfoResponse = this.$store.state.context!.selectedClientInvitationInfo;
                const selectedClientInvitation: ClientInvitation = this.$store.state.context!.selectedClientInvitation;
                
                if(
                    selectedClientInvitationInfo &&
                    selectedClientInvitationInfo.jobId == 
                    selectedClientInvitation!.id
                ) return true
                return false;
			} else {
				return false;
			}
		},

		async countDownTimer(): Promise<void> {
			this.timeLeft = 2
			return new Promise((resolve) => {
				const downloadTimer = setInterval(() => {
					this.timeLeft--
					if (this.timeLeft <= 0) {
						clearInterval(downloadTimer)
						resolve()
					}
				}, 1000)
			})
		},
        async refreshClientInvitationInfo(): Promise<void> {
            if(this.loadingInfo) return
			this.loadingInfo = true

            try {
                /* Create selectedClientInvitation */
				//const clientInvitation: ClientInvitation = {
				//id: hit.jobId,
				//	name: hit.displayName
				//}

				/* Store selection */
            	//await this.$vStore.dispatch("context/setClientInvitation", clientInvitation)

                await this.$vStore.dispatch("context/getClientInvitationInfo")
                this.loadingInfo = false;
            } catch(e){
                console.warn("Error: getClientInvitationInfo()")
            }
		},
		cancel(): void {
			if(this.loadingActive || this.loadingApproval) return
			this.$emit("close", null)
		},
        
        async copyLink(){
            let snackbarInfo: SnackbarInfo;
            const link = this.invitationUrl;
            try {
                await navigator.clipboard.writeText(link);
                snackbarInfo = {
                    success: true,
                    msg: "Successfully copied link to clipboard!",
                    e: null
                }
                this.copied = true
            } catch(e){
                snackbarInfo = {
                    success: false,
                    msg: "Copy link to clipboard failed.",
                    e: null
                }
                this.copied = false
            }

            this.$emit('showSnackbarInfo', false, 
                snackbarInfo, false, 1)
            
            if(this.clientInvitationInfo) this.$emit('resetCopyLink', this.clientInvitationInfo.jobId)
        },

        async toggleActive(): Promise<void> {
            this.deleteDialog = false
            this.loadingActive = true
            let snackbarInfo: SnackbarInfo;

            try{
                const changeActiveRequest: ChangeActiveRequest = { active: false }
                await this.$vStore.dispatch("context/changeActiveRequest", changeActiveRequest)
                await this.countDownTimer()
                await this.$vStore.dispatch("context/updateClientInvitations")

                this.loadingActive = false
                this.cancel();
            } catch(e){
                snackbarInfo = {
                    success: false,
                    msg: "Update active status failed.",
                    e: e
                }
                this.$emit('showSnackbarInfo', false, 
                    snackbarInfo, false, 3)
                this.loadingActive = false
            }
        },

        async toggleManualApproval(): Promise<void> {
            this.loadingApproval = true
			let snackbarInfo: SnackbarInfo;

            try {
                if(!this.clientInvitationInfo){
                    console.warn("Invitation info has failed to load.")
                    return;
                }
                const changeManualApprovalRequest: ChangeManualApprovalRequest = { manualApproval: !this.clientInvitationInfo.manualApproval }
                await this.$vStore.dispatch("context/changeManualApprovalRequest", changeManualApprovalRequest)
                await this.countDownTimer()
                await this.$vStore.dispatch("context/getClientInvitationInfo")
                await this.$vStore.dispatch("context/updateClientInvitations")
            } catch(e){
                snackbarInfo = {
                    success: false,
                    msg: "Update manual approval status failed.",
                    e: e
                }
                this.$emit('showSnackbarInfo', false, 
                    snackbarInfo, false, 3)
            }
            
            this.loadingApproval = false
        }
	},
})
